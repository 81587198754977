// common passwords as an array of strings

const commonPasswords = [
  "gdfgdfgdfgdfg@Q1",
  "123456",
  "qwerty",
  "password",
  "111111",
  "Abc123",
  "123456789",
  "12345678",
  "123123",
  "1234567890",
  "12345",
  "1234567",
  "qwertyuiop",
  "qwerty123",
  "1q2w3e",
  "password1",
  "123321",
  "Iloveyou",
  "12345",
  "Test!123456"
];

export default commonPasswords;
