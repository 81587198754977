<template>
  <div
    :class="
      setInternally ? 'internalProfileContainer' : 'createProfileContainer'
    "
    v-loading="isReceiving"
  >
    <div v-show="!setInternally" class="create-profile-title">
      <div>
        {{ __("Create Profile") }}
      </div>
      <div class="welcome-dot"><span>.</span></div>
    </div>
    <el-form
      ref="createProfileForm"
      :key="createProfileFormKey"
      label-position="top"
      label-width="100px"
      :model="createProfile"
      :rules="rules"
      hide-required-asterisk
    >
      <el-form-item
        prop="display_name"
        :label="__('Full Name')"
        class="custom-form-item text"
      >
        <el-input
          v-model="createProfile.display_name"
          :placeholder="__('Please input the display name')"
        />
      </el-form-item>

      <el-form-item
        prop="password"
        :label="__('Password')"
        class="custom-form-item text"
      >
        <div slot="label" class="form-label">
          <div>{{ __("Password") }}</div>
          <div>
            <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
              :content="passwordHintPopover"
            >
              <i slot="reference" class="el-icon-info icon"></i>
            </el-popover>
          </div>
        </div>
        <el-input
          type="password"
          v-model="createProfile.password"
          :placeholder="__('Please input password')"
          autocomplete="off"
          show-password
        />
        <password-meter :password="createProfile.password" @score="onScore" />
      </el-form-item>

      <el-form-item
        prop="password_confirmation"
        :label="__('Confirm Password')"
        class="custom-form-item text"
      >
        <el-input
          v-model="createProfile.password_confirmation"
          :placeholder="__('Please input the password again')"
          type="password"
          autocomplete="off"
        />
      </el-form-item>

      <el-form-item>
        <save-button
          type="primary"
          id="save-profile-btn"
          class="w-full text"
          :loading="isLoading"
          @click="createOrEditProfile"
          :primaryKey="id"
          variant="UpdateSave"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PasswordMeter from "@/components/PasswordMeter/password-meter.vue";
import _ from "lodash";
import SaveButton from "@/components/SaveButton";

export default {
  name: "createProfile",
  components: {
    PasswordMeter,
    SaveButton
  },
  props: {
    profileToEdit: {
      type: Object,
      required: false,
      default: () => ({})
    },
    id: {
      type: [String, Number],
      required: false,
      default: null
    },
    setInternally: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(__("Please input the password"));
      } else {
        this.$nextTick(() => {
          if (this.pwdScore < 1) {
            callback(__("Password is not secure enough"));
          }
          if (!_.isEmpty(this.createProfile.password_confirmation)) {
            this.$refs.createProfileForm.validateField("password_confirmation");
          }
          callback();
        });
      }
    };
    const validateConfirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(__("Please input the password again"));
      } else if (value !== this.createProfile.password) {
        callback(__("Two passwords don't match!"));
      } else {
        callback();
      }
    };
    return {
      createProfileFormKey: 0,
      createProfile: {
        display_name: "",
        password: "",
        password_confirmation: "",
        email: ""
      },
      rules: {
        display_name: {
          required: true,
          message: __("Please enter the display name"),
          trigger: "blur"
        },
        password: [
          { required: true, validator: validatePassword, trigger: "change" }
        ],
        password_confirmation: [
          {
            required: true,
            validator: validateConfirmPassword,
            trigger: "change"
          }
        ]
      },
      isReceiving: false,
      email: "",
      pwdScore: null
    };
  },

  computed: {
    ...mapState("users", {
      isLoading: state => state.isLoading,
      isValid: state => state.validRequest,
      isProfileCreated: state => state.isProfileCreated
    }),

    /**
     * Content for the password hint popover
     * @returns {String} Translated content for the password hint popover
     */
    passwordHintPopover() {
      // eslint-disable-next-line
      return __("Password must be 8 characters long. It should contain at least one number, one special character and both upper case and lower case. Password should not be previously used password or a word which is easy to guess.");
    }
  },

  async mounted() {
    this.isReceiving = true;
    if (this.setInternally) {
      this.email = this.profileToEdit.email;
      this.isReceiving = false;
    } else if (!this.isProfileCreated) {
      // isProfileCreated would be set to true once api returns isCreated as true. this will prevent user to go back to profile page from browser back button.
      this.email = this.$route.query.email
        .replace(/ /g, "+")
        .replace(/%40/gi, "@")
        .replace(/%3A/gi, ":")
        .replace(/%24/g, "$")
        .replace(/%2C/gi, ",")
        .replace(/%3B/gi, ";")
        .replace(/%20/g, "+");
      await this.validateEmailAndToken(
        this.email,
        this.$route.query.token,
        "VERIFICATION"
      );
      if (this.isValid) {
        this.isReceiving = false;
      } else {
        this.isReceiving = false;
        this.$message({
          // eslint-disable-next-line
          message: __("Your invitation has expired. Please contact the person who invited you, so they can send you another invitation."),
          type: "error",
          duration: 8000,
          showClose: true
        });
        this.$router.push({ path: "/login" }).catch(() => {});
      }
    } else {
      this.$router.push({ path: "/login" }).catch(() => {}); //profile is already set. if user press back button then redirect to login
    }
  },

  async created() {},

  methods: {
    ...mapActions("users", {
      validateProfileRequest: "validateProfileRequest",
      createProfileRequest: "createProfileRequest"
    }),

    async validateEmailAndToken(email, token, type) {
      let obj = { email, token, type };
      await this.validateProfileRequest(obj);
    },

    onScore({ score }) {
      // score: 0 -> 4
      // strength one of : 'risky', 'pass', 'weak', 'safe' , 'secure'
      this.pwdScore = score;
    },

    createOrEditProfile: function() {
      this.$refs.createProfileForm.validate(valid => {
        if (valid) {
          this.isReceiving = true;
          const process = !this.id
            ? this.createProfileRequest
            : this.createProfileRequest;
          this.createProfile.email = this.email;
          process(this.createProfile)
            .then(() => {
              this.isReceiving = false;
              this.createProfileFormKey =
                this.createProfileFormKey + !this.id ? 0 : 1; // hack to refresh the sp plan form to persist recent modifications
              if (this.setInternally) {
                this.$emit("password-set", true);
              } else if (!this.id) {
                this.$message({
                  type: "success",
                  // eslint-disable-next-line
                  message: __("Profile created Successfully. Please login to continue.")
                });
                setTimeout(
                  () => this.$router.push({ path: "/login" }).catch(() => {}),
                  500
                );
              } else {
                this.$router
                  .push({
                    name: "login",
                    params: {
                      // eslint-disable-next-line
                      msg: __("Cannot create profile. Please contact your administrator.")
                    }
                  })
                  .catch(() => {});
              }
            })
            .catch(data => {
              this.isReceiving = false;
              this.$message({
                type: "error",
                message: data.message || __("Operation failed")
              });
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.createProfileContainer {
  padding-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  width: 476px;
  height: 538px;
  background: #ffffff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .create-profile-title {
    font-weight: bolder;
    font-size: 36px;
    display: flex;
    flex-direction: row;
    color: $--color-text-primary;
    margin-bottom: 20px;
    .welcome-dot {
      text-shadow: 0 4px 4px rgba(28, 209, 161, 0.26);
      color: $--color-primary;
    }
  }

  ::v-deep .el-form-item--medium .el-form-item__label {
    line-height: 7px;
    font-size: 1rem;
  }

  ::v-deep .el-input--medium .el-input__inner {
    height: 50px;
    line-height: 50px;
  }

  ::v-deep .el-form-item__label {
    display: flex;
    justify-content: space-between;
  }

  .custom-form-item {
    margin: 40px 0px;
  }

  #save-profile-btn {
    border-radius: 5px;
    height: 50px;
  }
}

.internalProfileContainer {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  background: #ffffff;

  ::v-deep .el-input--medium .el-input__inner {
    height: 40px;
    line-height: 50px;
  }

  ::v-deep .el-form-item__label {
    display: flex;
    justify-content: space-between;
    padding-top: 4px;
  }

  #save-profile-btn {
    border-radius: 5px;
    height: 40px;
  }
}

.w-full {
  width: 100%;
}
</style>
