<template>
  <div class="po-password-strength-bar" :class="passwordClass"></div>
</template>

<script>
import commonPasswords from "./commonPasswords";
import {
  lowercaseOnly,
  numbersOnly,
  repeatChar,
  specialCharOnly,
  uppercaseOnly
} from "@/utils/regex";

export default {
  name: "password-meter",
  props: {
    password: String
  },
  methods: {
    nameScore(score) {
      switch (score) {
        case 0:
          return "risky";
        case 1:
          return "pass";
        case 2:
          return "weak";
        case 3:
          return "safe";
        case 4:
          return "secure";
        default:
          return null;
      }
    },
    scorePassword(pass) {
      const DEFAULT_MIN_CHARACTER_LENGTH = 8;

      let score = 0;
      let length = 0;
      let nonRepeat = 0;
      let minRequirement = 0;

      const specialCharRegex = specialCharOnly;
      const lowercaseRegex = lowercaseOnly;
      const uppercaseRegex = uppercaseOnly;
      const numberRegex = numbersOnly;
      const repeatCharRegex = repeatChar;

      const hasSpecialChar = specialCharRegex.test(pass);
      const hasLowerCase = lowercaseRegex.test(pass);
      const hasUpperCase = uppercaseRegex.test(pass);
      const hasNumber = numberRegex.test(pass);
      const hasRepeatChars = repeatCharRegex.test(pass);
      const hasMinLength = pass.length >= DEFAULT_MIN_CHARACTER_LENGTH;
      const isCommonPassword = commonPasswords.includes(pass);

      if (!hasMinLength) {
        return 0;
      }
      if (isCommonPassword) {
        return 0;
      }

      //check minimum requirements
      if (hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar) {
        minRequirement = 1;
      } else {
        return 0;
      }

      //check a repeat series of chars
      if (!hasRepeatChars) {
        nonRepeat = 1;
      }

      //check password length
      if (pass.length > 12) {
        length = 1;
      }

      if (pass.length > 20) {
        length = 2;
      }

      score = minRequirement + nonRepeat + length;
      return score;
    }
  },
  computed: {
    passwordClass() {
      if (!this.password) {
        return null;
      }
      const score = this.scorePassword(this.password);
      const strength = this.nameScore(score);
      this.$emit("score", { score, strength });
      return {
        [strength]: true,
        scored: true
      };
    }
  }
};
</script>

<style>
.po-password-strength-bar {
  border-radius: 2px;
  transition: all 0.2s linear;
  height: 5px;
  margin-top: 8px;
}

.po-password-strength-bar.risky {
  background-color: #f95e68;
  width: 10%;
}

.po-password-strength-bar.pass {
  background-color: #fb964d;
  width: 32.5%;
}

.po-password-strength-bar.weak {
  background-color: #fdd244;
  width: 55%;
}

.po-password-strength-bar.safe {
  background-color: #b0dc53;
  width: 77.5%;
}

.po-password-strength-bar.secure {
  background-color: #35cc62;
  width: 100%;
}
</style>
