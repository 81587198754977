import { render, staticRenderFns } from "./createProfile.vue?vue&type=template&id=f7bbbdb4&scoped=true"
import script from "./createProfile.vue?vue&type=script&lang=js"
export * from "./createProfile.vue?vue&type=script&lang=js"
import style0 from "./createProfile.vue?vue&type=style&index=0&id=f7bbbdb4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7bbbdb4",
  null
  
)

export default component.exports